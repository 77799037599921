@import url("fontawesome-all.min.css");
@import url("https://fonts.googleapis.com/css?family=Lato:400,400italic,700,700italic|Source+Code+Pro:400");

body {
    font-family: "Lato";
}

#sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*background: #1e8cc7;*/
    background: #1e8cc7;
    color: #d2f2e9;
    height: 100%;
    overflow-y: auto;
    position: fixed;
    text-align: center;
    top: 0;
    width: 23em;
    right: 0;
}